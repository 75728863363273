<template>
  <div class="dashboard" style="padding-top: 16px;">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>请输入手机号、学员名称进行结果查询</span>
              </template>
              <a-input v-model="keywords" placeholder="请输入手机号、学员名称进行结果查询" style="width: 350px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-card class="box" title="相关学员">
      <div class="student-list">
        <div class="student-item" v-for="(student, index) of students" :key="index" @click="toProfile(student)">
          <div class="photo"><LImg :src="student.student_avatar"/></div>
          <div class="name"><a>{{ student.student_name }}</a></div>
          <div class="phone">{{ student.cellphone }}</div>
        </div>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams1.totalCount"
          showSizeChanger
          :pageSize="pageParams1.perPage"
          v-model="current1"
          @change="changePage1"
          @showSizeChange="onShowSizeChange1"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </a-card>
    <a-card class="box" title="相关班级">
      <div class="table">
        <a-table size="small" :pagination="false" :bordered='false' rowKey="class_id"
          :columns="columns" :dataSource="classes">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams2.perPage * (pageParams2.currentPage - 1)}}</span>
            </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams2.totalCount"
          showSizeChanger
          :pageSize="pageParams2.perPage"
          v-model="current2"
          @change="changePage2"
          @showSizeChange="onShowSizeChange2"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </a-card>
    <profileModal v-if="profileVisible" :studentId="studentId"/>
  </div>
</template>
<style lang="scss" scoped>
.box {
  margin-bottom: 8px;
}
.student-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  .student-item {
    border: 1px solid #f1f1f1;
    padding: 10px;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
</style>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '班级名称', dataIndex: 'class_name', key: 'name'},
  { title: '上课校区', dataIndex: 'studio_id', key: '1' },
  { title: '授课老师', dataIndex: 'cc_id', key: '2' },
  { title: '班主任', dataIndex: 'tutor_id', key: '31' },
  { title: '课程科目', dataIndex: 'course_cate_id', key: '3' },
  { title: '班级类型', dataIndex: 'class_type', key: '4' },
  { title: '课程进度', dataIndex: 'class_percent', key: '5' },
  { title: '开班时间', dataIndex: 'class_start', key: '7' },
]
										
import profileModal from '@/views/modal/profile'
export default {
  components: {
    profileModal
  },
  provide() {
    return {
      parent: this
    }
  },
  data() {
    return {
      keywords: '',
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current1: 1,
      pageParams1: {},
      searchParams1: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      students: [],
      
      columns,
      current2: 1,
      pageParams2: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchParams2: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      classes: [],
      profileVisible: false,
    }
  },
  mounted() {
    let {keywords} = this.$route.params
    if (keywords) {
      this.keywords = keywords
    }
    this.searchList()
  },
  methods: {
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams1.page = 1
      this.searchParams2.page = 1
      this.getStudentsList()
      this.getClassesList()
    },
    async getStudentsList() {
      this.searchParams1.search.fuzzy = this.keywords
      let res = await this.$store.dispatch('studentsAction', { data: this.searchParams1 })
      this.students = res.items
      this.pageParams1 = res._meta
    },
    async getClassesList() {
      this.searchParams2.search.class_name = this.keywords
      let res = await this.$store.dispatch('classesListAction', this.searchParams2)
      this.classes = res.items
      this.pageParams2 = res._meta
    },
    changePage1(page, pageSize) {
      this.searchParams1.page = page
      this.getStudentsList()
    },
    onShowSizeChange1(current, size) {
      this.searchParams1['per-page'] = size
      this.getStudentsList()
    },
    changePage2(page, pageSize) {
      this.searchParams2.page = page
      this.getClassesList()
    },
    onShowSizeChange2(current, size) {
      this.searchParams2['per-page'] = size
      this.getClassesList()
    },

    toProfile(item) {
      this.studentId = item.student_id
      this.showProfileModal()
    },
    showProfileModal() {
      this.profileVisible = true
    },
    hideProfileModal() {
      this.profileVisible = false
    },
  }
}
</script>
